import { Component } from "react";

export default class Privacy extends Component {
    render() {
        return (
            <main className='mx-8 lg:mx-64 my-8 flex flex-col'>
                <section className="lg:my-8 lg:mx-32 xl:mx-48 2xl:mx-96 mx-4">
                    <h1 className="text-black text-4xl font-medium mb-4">
                        Privacy Policy
                    </h1>

                    <p className="text-black">
                        All rights of the watchdock.net domain website (hereinafter referred to as the “Site”) belong to Watchdock and Software Inc. (hereinafter referred to as “Watchdock”). Watchdock will not disclose personal information transmitted electronically by users through the Site to third parties beyond the purposes and scope determined by the agreements it has made with its members and users.
                    </p>
                    <br />
                    <p className="text-black">
                        Watchdock may detect and use users' IP addresses when necessary. IP addresses can also be used to identify users generally and to gather comprehensive demographic information.
                    </p>
                    <br />
                    <p className="text-black">
                        By filling out various forms and polls on the Site, users are required to provide some personal information (such as name-surname, phone, address, or email addresses) to Watchdock. The information requested by Watchdock and/or provided by the user and/or related to the transactions carried out through the Site; can be used by Watchdock and its collaborators for statistical evaluations, database creation, and market research without revealing the identity of the members and users, beyond the purposes and scope determined by the agreements Watchdock has made with its members and users.
                    </p>
                    <br />
                    <p className="text-black">
                        Watchdock may link to other sites within the Site. Watchdock is not responsible for the privacy practices and content of the sites accessed through these links.
                    </p>
                    <br />
                    <p className="text-black">
                        Unless otherwise stated in this privacy policy, Watchdock will not disclose any personal information to companies and third parties with which it is not in cooperation. In limited cases specified below, Watchdock may disclose user information to third parties outside the provisions of this “Privacy Policy.” These limited cases are:
                    </p>
                    <br />
                    <p className="text-black">
                        Compliance with the obligations imposed by the legal rules such as Law, Decree Law, Regulation, etc., issued by the competent legal authorities and in force; the fulfillment and implementation of the requirements of the agreements made by Watchdock with its members and users; the necessity of providing information about users in the context of a duly conducted research or investigation by the competent administrative and judicial authority; and the necessity of providing information to protect the rights or security of users.
                    </p>
                    <br />
                    <p className="text-black">
                        Watchdock commits to keep personal information strictly private and confidential; to consider it a confidentiality obligation; to take all necessary measures to ensure and maintain confidentiality; to prevent any part of confidential information from entering the public domain or unauthorized use or disclosure to a third party; and to show the necessary care. In case confidential information is damaged or obtained by third parties as a result of attacks on the Site and the system, despite the necessary information security measures taken by Watchdock, Watchdock will not have any responsibility.
                    </p>
                    <br />
                    <p className="text-black">
                        Watchdock may obtain information about users and their use of the Site using a technical communication file (Cookie). These technical communication files are small text files sent by a website to the user's browser to be stored in the main memory. The technical communication file makes the internet usage easier by saving the status and preferences about a website. The technical communication file can be used to obtain statistical information about how many people use the website, for what purpose, how many times a person visits the website, and how long they stay. Additionally, it helps produce dynamically permitted marketing messages and content from specially designed user pages. The technical communication file is not designed to retrieve data or any other personal information from the main memory or the user's email address. Most browsers are initially designed to accept the technical communication file, but users can change the settings to prevent the file from being sent or to receive a warning when the file is being sent if they prefer.
                    </p>
                    <br />
                    <p className="text-black">
                        Users can contact us at team@watchdock.net to delete their registered data.
                    </p>
                    <br />
                    <p className="text-black">
                        Watchdock may unilaterally change the provisions of this Privacy Policy by publishing them on the Site at any time. The changes made by Watchdock to the Privacy Policy provisions become effective on the date they are published on the Site.
                    </p>
                    <br />
                    <p className="text-black">
                        For any questions, you can contact us at info@watchdock.net.
                    </p>
                    <br />
                </section>
            </main>
        );
    }
}