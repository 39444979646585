import { Component } from "react";

export default class Terms extends Component {
    render() {
        return (
            <main className='mx-8 lg:mx-64 my-8 flex flex-col'>
                <section className="mx-4 lg:mx-32 xl:mx-48 2xl:mx-96 text-black">
                    <h1 className="text-4xl font-medium mb-4">
                        Terms of Use
                    </h1>

                    <h3 className="text-xl font-medium mb-4 mt-4">1. Parties</h3>
                    <p>
                        This agreement and the Watchdock Membership Agreement, which is constituted by referencing the Privacy Policy and which is an integral part of the agreement (hereinafter referred to as the “Membership Agreement”), is mutually accepted by and between Watchdock and Software Inc. (hereinafter referred to as Watchdock) and the Member who registers on the watchdock.net website under the conditions specified in this Membership Agreement, upon the Member’s electronic approval and subject to the condition in Article 4.
                        <br />
                        Before accepting this Membership Agreement, the Member declares and accepts that they have read, reviewed, and understood the pre-contract information text provided for commercial purposes, accessible and downloadable at https://watchdock.net/user-agreement, and that they have been fully informed in accordance with the pre-contractual information obligation, and that they have obtained all pre-contractual information regarding the agreement.
                    </p>
                    <p>
                        By completing the procedures related to the Membership Agreement, the Member declares and accepts that they have read the entire Membership Agreement, fully understood its content, that the information provided about themselves is accurate and up-to-date, and that they unconditionally accept and approve all the terms and conditions related to this Membership Agreement stated in the Web Site, and that they will not raise any objections or defenses regarding these matters.
                        The Member declares, accepts, and undertakes that they have read the content, essential characteristics, and features of the service subject to the Membership Agreement, that no fee is charged, and that they have provided the necessary approval for the Membership Agreement in an electronic environment, and that they have been informed about the services in line with this information.
                    </p>
                    <p>
                        You can download and save this agreement to your computer. The Member declares and accepts that they have downloaded this Membership Agreement to their computer and that the Membership Agreement has been provided to them in this way with a permanent data storage device. The Membership Agreement, approved by the Member, is not stored by Watchdock. You can always access the standard agreement available on the Website. However, since changes may have been made to the standard agreement, it may differ from the text approved by the Member. The Member does not have the opportunity to access the approved text later.
                    </p>
                    <p>
                        The clarification text prepared by Watchdock within the scope of Law No. 6698 on the Protection of Personal Data, regarding the processing of personal data, is published on the Website. The Member declares and accepts that they have read and reviewed the aforementioned clarification text and privacy policy, and that Watchdock has fulfilled its information obligation under the relevant law.
                    </p>
                    <h2 className="text-xl font-medium mb-4 mt-4">2. Definitions</h2>
                    <p>
                        Watchdock: Watchdock and Software Inc.
                        <br />
                        Member: The real or legal person who registers on the Site and benefits from the Services offered on the Site under the conditions specified in this agreement and makes comments on the products on the site.
                    </p>
                    <p>
                        User(s): Real and legal persons accessing the Site online;
                    </p>
                    <p>
                        Site or Website: The website consisting of the domain name watchdock.net and the subdomains attached to this domain name.
                    </p>
                    <p>
                        Seller(s): Real and/or legal person(s) who have signed a contract with Watchdock to list explanatory information about the goods and products they offer for sale on their own websites and sales conditions to Users and Members through the Site;
                    </p>
                    <p>
                        Watchdock Services (Briefly "Service"): Applications presented within the Site by Watchdock to enable Members to perform the tasks and transactions defined in the Membership Agreement.
                    </p>
                    <h2 className="text-xl font-medium mb-4 mt-4">
                        3. Subject and Scope of the Agreement
                    </h2>
                    <p>
                        The subject of the Membership Agreement is to determine the conditions for benefiting from the Services offered on the Site and the rights and obligations of the parties. By accepting the terms of the Membership Agreement, the Member also accepts all procedures, rules, and statements related to the usage, membership, and Services declared by Watchdock on the Site. The Member declares, accepts, and undertakes to act in accordance with all matters stated in the said procedures, rules, and statements.
                    </p>
                    <h2 className="text-xl font-medium mb-4 mt-4">
                        4. Membership and Service Usage Conditions
                    </h2>
                    <p>
                        The Website is an electronic platform where price and other features of various products are compared. Companies engaged in the sale of various goods and services (Sellers) can benefit from this service by registering on the Site to reach a wider consumer audience. Our company is not the seller or producer of any product and is not engaged in product sales or production. No product sales are made on our website. Our company does not take part in any stage of the product or service sales chain to consumers. Watchdock cannot be held responsible for any relationships between the Member and Sellers. Watchdock does not guarantee in any way that the Sellers will contract with the Member, that they are acting in good faith, that they have the ability to fulfill their obligations, that their statements are accurate, that they are authorized to act, that the goods and services provided will be defect-free, and other similar matters. Watchdock is completely unrelated to and has no responsibility for the legal relationships that the Member will enter into with the Sellers reached through the Site. The entire responsibility lies with the Sellers and the Member. Watchdock, as a “Hosting Provider” under Law No. 5651, is not obliged to control the content provided by the Sellers or to investigate whether there is any unlawful activity.
                    </p>
                    <p>
                        Membership is completed by sending the necessary identity information required for registration to the Site from the relevant section of the Site, or by making comments on the products on the site, and the registration is approved by Watchdock. Without completing the membership process, one cannot have the rights and authority to be a Member as defined in this agreement.
                    </p>
                    <p>
                        Benefiting from the Services is free for the Member. Watchdock reserves the right to make the Services chargeable in the future and/or add services for a fee.
                    </p>
                    <h2 className="text-xl font-medium mb-4 mt-4">
                        5. Rights and Obligations of the Parties
                    </h2>
                    <p>
                        5.1 The Member declares, accepts, and undertakes to act in accordance with all the terms specified in the Membership Agreement, the rules specified in the relevant parts of the Site, and all applicable legislation while fulfilling the membership procedures, benefiting from the Services of the Site, and performing any transaction related to the Services on the Site.
                        <br />
                        5.2 The Member declares, accepts, and undertakes that, in accordance with the mandatory provisions of the current legislation or in cases where it is alleged that the rights of other members, Users, or third parties have been violated, Watchdock will be authorized to disclose their confidential/special/commercial information to the official authorities and the right holders, and that no compensation can be claimed from Watchdock under any circumstances for this reason.
                    </p>
                    <p>
                        5.3 The security, storage, keeping away from the knowledge of third parties, and use of the access tools (Username, password, etc.) used by the Members to benefit from the Services offered by Watchdock are entirely the responsibility of the Members. Watchdock has no direct or indirect responsibility for any damages that the Members and/or third parties may incur due to the negligence and faults of the Members regarding the security, storage, keeping away from the knowledge of third parties, and use of the access tools.
                    </p>
                    <p>
                        5.4 Members cannot transfer their rights and obligations under the Membership Agreement, partially or completely, to any third party without the written consent of Watchdock.
                    </p>
                    <p>
                        5.5 Watchdock will ensure that the Members access the product descriptions listed on the Site and that all kinds of comparisons related to the model, type, quality, characteristics, and/or features of the products are made. Watchdock may make changes and/or adaptations to its Services at any time, as well as offer additional Services, to enable the Members to perform the tasks and transactions defined in the Membership Agreement more effectively. These changes and/or adaptations and/or new Services added by Watchdock will be announced to the Members on the web page where the relevant Service usage explanations are located.
                    </p>
                    ```html
                    <p>
                        5.6 Those who benefit from the Services offered by Watchdock and use the Site can only perform transactions on the Site for lawful purposes. The legal and criminal liability for every transaction and action taken by Members on the Site belongs to them. Each Member agrees, declares, and undertakes that they will not reproduce, copy, distribute, process the images, texts, visual and audio images, video clips, files, databases, catalogs, and lists available on the Site in a manner that constitutes an infringement of Watchdock and/or another third party's real or personal rights or property rights, and that they will not compete directly and/or indirectly with Watchdock either through these actions or through other means. Watchdock cannot be held directly and/or indirectly liable in any way for the damages that third parties may incur or might incur due to the activities that Members carry out on the Site in violation of the provisions of the Membership Agreement and/or the law.
                    </p>
                    <p>
                        5.7 Watchdock reserves the right to change the Services and content offered on the Site at any time; to close and delete these contents to third parties, including Members and those using the Site. Watchdock can use this right without making any notification and without prior notice.
                    </p>
                    <p>
                        5.8 Watchdock can use Member information on the Site in any way for member security, fulfilling its own obligations, and for some statistical evaluations. It can classify and preserve these on a database.
                    </p>
                    <p>
                        5.9 Members accept, declare, and undertake that Watchdock, Watchdock employees, and managers have no responsibility in any way due to the products/products offered for sale and/or published content/contents on the websites of Sellers where the sale of products accessed through the Site will occur.
                    </p>
                    <p>
                        5.10 Members agree that the site may send informational e-mails by becoming a member of the site. The right to use the e-mail addresses and/or content of users who write comments on the products on the site is reserved to watchdock.net, and the Member is considered to have accepted receiving e-mails from watchdock.net.
                    </p>
                    <h2 className="text-xl font-medium mb-4 mt-4">
                        6. Privacy Policy
                    </h2>
                    <p>
                        Watchdock may use information related to Members in accordance with the provisions set out in the Privacy Policy section of this agreement. Watchdock does not allow third parties and institutions to use confidential information belonging to Members, except in cases permitted by the Membership Agreement.
                    </p>
                    <h2 className="text-xl font-medium mb-4 mt-4">7. Other Provisions</h2>
                    <p>7.1. Intellectual Property Rights</p>
                    <ol>
                        <li>
                            All elements of the Site, including but not limited to design, text, image, HTML code, and other codes (hereinafter referred to as "Works Subject to Watchdock's Copyrights") belong to Watchdock and/or are used under a license obtained from a third party by Watchdock. Members may not resell, share, distribute, exhibit, or allow others to access or use the Services, Watchdock information, and Works Subject to Watchdock's Copyrights. Otherwise, they will be liable for any damages incurred by Watchdock and/or third parties, including licensors, and/or the amount of compensation claimed from Watchdock for these damages, including court costs and attorney fees. Members cannot reproduce, distribute, or prepare derivative works or prepare any part of Works Subject to Watchdock's Copyrights.
                        </li>
                        <li>
                            All property rights, real and personal rights, commercial information and know-how, including the Services, information, Works Subject to Watchdock's Copyrights, trademarks, trade dress, or all types of material and intellectual property rights owned by Watchdock through the Site, are reserved.
                        </li>
                    </ol>
                    <p>7.2. Contract Amendments</p>
                    <p>
                        Watchdock may unilaterally change this Membership Agreement at any time by posting it on the Site at its sole discretion. The changed provisions of this Membership Agreement will become valid on the date they are posted, and the remaining provisions will continue to remain in effect and produce results. This Membership Agreement cannot be changed by the unilateral declarations of the Member.
                    </p>
                    <p>7.3. Force Majeure</p>
                    <p>
                        In all circumstances considered legally force majeure, Watchdock is not liable for any delayed or incomplete performance or non-performance of any of its obligations determined by this agreement. Such situations will not be considered as delayed, incomplete performance or non-performance, or default for Watchdock, and no compensation can be claimed from Watchdock for these situations in any name. The term "force majeure" will be interpreted as events that are beyond the reasonable control of the relevant party and that Watchdock could not prevent despite showing necessary care, including but not limited to natural disasters, riots, war, strikes, communication problems, infrastructure and internet failures, power outages, and adverse weather conditions.
                    </p>
                    <p>7.4. Applicable Law and Jurisdiction</p>
                    <p>
                        Turkish Law will be applied in the implementation, interpretation, and management of the legal relations arising within the provisions of this User Agreement. Antalya Courts and Execution Offices are authorized for the resolution of any disputes arising or that may arise from this User Agreement.
                    </p>
                    <p>7.5. Termination of the Agreement</p>
                    <p>
                        This Membership Agreement is arranged indefinitely. watchdock.net has the right to terminate the agreement at any time without needing to specify any period.
                    </p>
                    <p>
                        Watchdock may unilaterally terminate the agreement if Members violate this Membership Agreement and/or similar rules regarding use, membership, and Services on the Site, and especially in the following cases, and Members will be liable to compensate all damages incurred by Watchdock due to the termination:
                    </p>
                    <ol>
                        <li>
                            The Member's behavior in a way that will manipulate the operation of the Site using any method,
                        </li>
                        <li>
                            Unauthorized reproduction, copying, distribution, and processing of images, texts, visual and audio images, files, databases, catalogs, and lists available on the Site;
                        </li>
                        <li>
                            The Member's acts that infringe and/or pose a threat to infringe the rights of other Members and/or users and/or third parties and/or right holders.
                        </li>
                    </ol>
                    <p>7.6. Duration of the Agreement</p>
                    <p>
                        This Agreement is arranged indefinitely. Each party has the right to terminate the agreement at any time.
                    </p>
                </section>
            </main>
        );
    }
}