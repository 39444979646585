/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";
import logo from './assets/logo.png';
import mockup from './assets/mockup.png';
import apple from './assets/apple.png';
import google from './assets/google.png';

export default class Landing extends Component {
    render() {
        return (
            <main className='mx-8 lg:mx-36 my-8 flex flex-col'>
                <header className='flex flex-col gap-y-4 lg:flex-row items-center justify-between'>
                    <a href='/'>
                        <img src={logo} alt='logo' className='w-16 h-16 object-contain rounded-xl' />
                    </a>
                    <nav className='flex flex-row items-center'>
                        <a href='/privacy-policy'
                            className='text-base font-medium text-gray-500 mx-4 hover:underline'>Privacy Policy</a>
                        <a href='/terms-of-use'
                            className='text-base font-medium text-gray-500 mx-4 hover:underline'>Terms of Use</a>
                    </nav>
                </header>

                <section className='flex flex-col justify-between items-center my-8'>
                    <h1 className='text-4xl font-semibold my-4 max-w-lg text-center'>Watchdock: Movies & TV Shows</h1>
                    <p className='text-gray-500 max-w-lg text-center'>Create & curate your own movie and TV show lists, and share your cinematic journey with friends.</p>

                    <div className='flex flex-row gap-x-2 mt-4 mb-8'>
                        <a href='https://apps.apple.com/tr/app/watchdock-movies-tv-shows/id6467408678'>
                            <img src={apple} alt='apple' className='h-12 object-contain' />
                        </a>
                        <a href='#'>
                            <img src={google} alt='google' className='h-12 object-contain' />
                        </a>
                    </div>

                    <img src={mockup} alt='mockup'
                        className='rounded-3xl' />
                </section>
            </main>
        );
    }
}