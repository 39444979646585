import { Component } from "react";

export default class Support extends Component {
    render() {
        return (
            <main className='mx-8 lg:mx-64 my-8 flex flex-col'>
                <section className="mx-4 lg:mx-32 xl:mx-48 2xl:mx-96 text-black">
                    <h1 className="text-4xl font-medium mb-4">
                        Support
                    </h1>

                    <p className="text-lg font-normal mb-4">
                        If you need help with anything, please contact us at <a href="mailto:hey@odin.studio" className="text-blue-500">
                            hey@odin.studio
                        </a>
                    </p>
                </section>
            </main>
        );
    }
}