/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import './App.css';
import Privacy from './Privacy';
import Landing from './Landing';
import Terms from './Terms';
import NotFound from './NotFound';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Support from './Support';

export default class App extends Component {
  render() {
    return (
      <main>
        <Router>
          <Switch>
            <Route path='/privacy-policy' exact component={Privacy} />
            <Route path='/terms-of-use' exact component={Terms} />
            <Route path='/support' exact component={Support} />

            <Route path='/' exact component={Landing} />
            <Route path='*' exact component={Landing} />
          </Switch>
        </Router>
      </main>
    );
  }
}
